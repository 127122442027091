<template>
  <div class="">
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
      <opciones />
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3>REGISTRO DE NUEVO CASILLERO:</h3>
      <v-flex text-right>
        <v-btn color="success" @click="editar()" class="ml-auto" small>
          PASAR A EDITAR
        </v-btn>
      </v-flex>
    </v-app-bar>
    <v-container elevation="10" fluid>
      <v-tabs fixed-tabs v-model="tabs">
        <v-tab> SOLICITAR </v-tab>
        <v-tab> Costos </v-tab>
        <v-tab> Instructivo </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card class="px-10 pt-5">
            <div class="col-12">
              <NuevoCasilleroView type="ver" />
            </div>
            <div class="col-12">
              <ServicioCasilleroView type="ver" />
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <CostosCasilleroView type="ver" />
        </v-tab-item>
        <v-tab-item>
          <InstructivoCasilleroView type="ver" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import funcion from "@/mixins/funciones";

// import { mapActions } from "vuex";
export default {
  name: "nuevoCasillero",
  mixins: [funcion],
  components: {
    opciones: () => import("@/components/comun/opcionesCasillero.vue"),
    NuevoCasilleroView: () =>
      import("@/components/casillero/nuevoCasilleroComponet.vue"),
    ServicioCasilleroView: () =>
      import("@/components/casillero/serviciosCasilleroComponent.vue"),
    CostosCasilleroView: () =>
      import("@/components/casillero/costosCasilleroComponent.vue"),
    InstructivoCasilleroView: () =>
      import("../../components/casillero/instructivoCasilleroComponent.vue"),
  },
  data() {
    return {
      mostrar: true,
      tabs: "",
      tabCostos: true,
      tabNotas: true,
      tabInstructivo: true,
      registrarFlag: true,
    };
  },
  computed: {
    ...mapState(["casillero", "costos", "multiplicador", "services"]),
  },
  methods: {
    ...mapMutations(["setCasillero"]),
    ...mapActions([
      "getLockerVer",
      "registarCasillero",
      "getInstructivoCasillero",
      "getItemsServices",
      "getItemsServicesDetails",
      "getListadoCasillero",
      "getModuleRole",
      "getMultiplicador",
      "getListadoQuoteCasillero",
    ]),

    editar() {
      this.$router.push({
        name: "editar-casillero",
        params: { id: this.$route.params.id },
      });
    },
  },
  mounted() {
    this.getLockerVer(this.$route.params.id);
    // this.getListadoQuoteCasillero();
    this.getModuleRole();
    // this.getMultiplicador(3);
    // this.getItemsServices({
    //   id_modality: 1,
    //   id_shipment: 3,
    //   id_incoterms: 9,
    // });
    // this.getListadoCasillero();
  },
};
</script>

<style></style>
